<app-content-wrapper [title]="'title.products' | translate" style="flex:1" [headerColor]="origin != 'templates' ? '#444444' : ''"
    [padding]="origin == 'templates'">
    @if (origin != 'locations') {
    <button mat-icon-button toolbar (click)="openAddProductTemplate()"><mat-icon>add</mat-icon></button>
    }
    <div content>

        <mat-form-field appearance="outline" *ngIf="origin == 'templates'">
            <mat-label>Filter...</mat-label>
            <input matInput (keyup)="applyFilter($event)">
        </mat-form-field>
        <table mat-table [dataSource]="dataSource" class="example-table" matSort matSortActive="created" matSortDisableClear matSortDirection="desc">

            <ng-container matColumnDef="id">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'form.productId' | translate}}</th>
                <td mat-cell *matCellDef="let row">{{row.productCode}}</td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'form.name' | translate}}</th>
                <td mat-cell *matCellDef="let row">{{row.name}}</td>
            </ng-container>

            <ng-container matColumnDef="serialName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'form.tagNumber' | translate}}</th>
                <td mat-cell *matCellDef="let row">{{row.serialName}}</td>
            </ng-container>

            <ng-container matColumnDef="positionNumber">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'form.positionNumber' | translate}}</th>
                <td mat-cell *matCellDef="let row">{{row.positionNumber}}</td>
            </ng-container>

            <ng-container matColumnDef="installationDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'form.installationDate' | translate}}</th>
                <td mat-cell *matCellDef="let row">{{row.installationDate | date:'dd.MM.YYYY'}}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>{{'form.actions' | translate}}</th>
                <td mat-cell *matCellDef="let row">
                    @if (origin == 'racks') {
                    <button mat-icon-button (click)="deleteRackProduct(row)">
                        <mat-icon>delete</mat-icon>
                    </button>
                    }
                    @if (origin != 'racks') {
                    <button mat-icon-button (click)="openProductDialog(row)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    }
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd" (click)="origin == 'templates' ? openProductDialog(row) : ''"
                [ngClass]="[odd ? 'odd-row' : '', activatedRow == row ? 'activated-row' : '']" (click)="activatedRow = row">
            </tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                    <div class="table-empty-row">
                        {{'form.emptyData' | translate}}
                    </div>
                </td>
            </tr>
        </table>
        <mat-paginator [pageSize]="origin != 'locations' ? 10 : 5" [hidePageSize]="true"></mat-paginator>
    </div>
</app-content-wrapper>
