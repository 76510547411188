import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentWrapperComponent } from '../../components/content-wrapper/content-wrapper.component';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Product } from '../../models/product';
import { ApiService } from '../../services/api.service';
import { MiscService } from '../../services/misc.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogModule,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { ProductComponent } from './product/product.component';
import { MatButtonModule } from '@angular/material/button';
import { Rack } from '../../models/rack';
import { Location } from '../../models/location';
import { ReactiveFormsModule, Validators, FormBuilder } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ConfirmComponent } from '../../components/confirm/confirm.component';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@Component({
  selector: 'app-products',
  standalone: true,
  imports: [
    CommonModule,
    MatSortModule,
    ContentWrapperComponent,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    TranslateModule,
    MatPaginatorModule,
  ],
  templateUrl: './products.component.html',
  styleUrl: './products.component.scss',
})
export class ProductsComponent {
  @Input('products') set products(value: Product[] | undefined) {
    this.dataSource = new MatTableDataSource(value);
    this.dataSource.paginator = this.paginator;
    if (this.dataSource.paginator?.pageIndex)
      this.dataSource.paginator.pageIndex = 0;
    this.dataSource.sort = this.sort;
  }
  @Input() rack?: Rack;
  @Input() origin: 'templates' | 'racks' | 'locations' = 'templates';
  @Input() location?: Location;
  @Output() onUpdateProduct = new EventEmitter<void>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  dataSource: MatTableDataSource<Product> = new MatTableDataSource();
  displayedColumns: string[] = ['id', 'name'];
  activatedRow: any;

  constructor(
    private api: ApiService,
    private misc: MiscService,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {}

  async ngOnInit() {
    console.log('Refresh Products!');
    this.displayedColumns = ['id', 'name'];
    if (this.origin == 'locations')
      this.displayedColumns.push(
        'positionNumber',
        'serialName',
        'installationDate',
        'actions'
      );
    if (this.origin == 'racks') this.displayedColumns.push('actions');

    if (this.origin == 'templates') {
      this.api.PRODUCTS_GetProductTemplates().subscribe({
        next: (res) => {
          if (res.ok && res.body) {
            this.dataSource = new MatTableDataSource(res.body);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
          } else {
            this.misc.showError(this.translate.instant('error.general'));
          }
        },
        error: (err) => {
          this.misc.showError(this.translate.instant('error.general'));
        },
      });
    } else {
      //this.dataSource = new MatTableDataSource(this.products);
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteRackProduct(product: Product) {
    if (!this.rack?.id) return;
    this.dialog
      .open(ConfirmComponent, {
        data: {
          title: this.translate.instant('form.deleteProduct'),
          message: this.translate.instant('form.deleteProductInfo'),
        },
      })
      .afterClosed()
      .subscribe((accepted) => {
        if (!accepted) return;
        this.api
          .RACKS_DeleteRackTemplateProduct(this.rack!.id, product.id)
          .subscribe({
            next: (res) => {
              if (res.ok) {
                this.onUpdateProduct.emit();
                this.misc.showInfo(
                  this.translate.instant('success.deleteProduct', {
                    product: product.name,
                  })
                );
              } else {
                this.misc.showError(this.translate.instant('error.general'));
              }
            },
            error: (err) => {
              this.misc.showError(this.translate.instant('error.general'));
            },
          });
      });
  }

  openAddProductTemplate() {
    if (this.origin == 'templates') {
      const dialogRef = this.dialog.open(ProductComponent, {
        data: { mode: this.origin },
        minWidth: '400px',
        maxHeight: '80vh',
      });
      dialogRef
        .afterClosed()
        .subscribe((res: { reload: boolean }) =>
          res?.reload ? this.ngOnInit() : ''
        );
    } else if (this.origin == 'racks') {
      const dialogRef = this.dialog.open(AddProductTemplateDialog, {
        data: this.rack,
        maxHeight: '80vh',
      });
      dialogRef
        .afterClosed()
        .subscribe((res: { reload: boolean }) =>
          res?.reload ? this.onUpdateProduct.emit() : ''
        );
    }
  }

  openProductDialog(product: Product) {
    const dialogRef = this.dialog.open(ProductComponent, {
      minWidth: '400px',
      maxHeight: '80vh',
      data: {
        productId: product.id,
        mode: this.origin,
        location: this.location ?? null,
      },
    });

    dialogRef.afterClosed().subscribe((res: { reload: boolean }) => {
      if (res?.reload) {
        if (this.origin == 'locations') {
          this.misc.showInfo(this.translate.instant('success.save'));
          this.onUpdateProduct.emit();
        } else {
          this.ngOnInit();
        }
      }
    });
  }
}

@Component({
  selector: 'add-product-template-dialog',
  templateUrl: 'add-product-template-dialog.html',
  styleUrl: './products.component.scss',
  standalone: true,
  imports: [
    ContentWrapperComponent,
    TranslateModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
  ],
})
export class AddProductTemplateDialog {
  filteredProducts: Product[] = [];
  products: Product[] = [];
  addProductControl = this.fb.control('', Validators.required);

  constructor(
    public dialogRef: MatDialogRef<AddProductTemplateDialog>,
    public fb: FormBuilder,
    private api: ApiService,
    private misc: MiscService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: Rack
  ) {}

  ngOnInit(): void {
    this.api.PRODUCTS_GetProductTemplates().subscribe({
      next: (res) => {
        if (res.ok && res.body) {
          this.products = res.body;
          this.filteredProducts = res.body;
        } else {
          this.misc.showError(this.translate.instant('error.general'));
        }
      },
      error: () => {
        this.misc.showError(this.translate.instant('error.general'));
      },
    });
  }

  productName(product: Product) {
    return product?.name ?? '';
  }

  filterProducts(event: any) {
    const filterValue = event.target.value.toLowerCase();
    this.filteredProducts = this.products.filter((product) =>
      product.name.toLowerCase().includes(filterValue)
    );
  }

  save() {
    this.api
      .RACKS_PostRackTemplateProduct(
        this.data.id,
        this.addProductControl.value as unknown as Product
      )
      .subscribe({
        next: (res) => {
          if (res.ok) {
            this.dialogRef.close({ reload: true });
            this.misc.showInfo(this.translate.instant('success.save'));
          } else {
            this.misc.showError(this.translate.instant('error.general'));
          }
        },
        error: () => {
          this.misc.showError(this.translate.instant('error.general'));
        },
      });
  }
}
