<app-content-wrapper [title]="'title.addProduct' | translate">
    <button mat-icon-button toolbar (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
    <form class="aa-form" content>

        <p>
            <mat-form-field floatLabel="always" appearance="outline">
                <mat-label>{{'title.products' | translate}}</mat-label>
                <input type="text" [formControl]="addProductControl" matInput [matAutocomplete]="auto" (input)="filterProducts($event)">

                <mat-autocomplete #auto="matAutocomplete" [displayWith]="productName" requireSelection>
                    @for (product of filteredProducts; track product) {
                    <mat-option [value]="product">{{product.name}}</mat-option>
                    }
                </mat-autocomplete>
            </mat-form-field>
        </p>
        <button type="button" mat-raised-button color="primary" [disabled]="addProductControl.invalid" (click)="save()">
            <mat-icon>save</mat-icon>
            {{'form.save' | translate}}
        </button>
    </form>
</app-content-wrapper>
